/* .servicesPageHeader {
  background-color: white;
  padding: 0.6rem 1.5rem 0px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContent h2 {
  margin: 0;
}

.headerContent button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.servicesPageHeader hr {
  border: none;
  border-top: 1px solid #eee;
} */

.serviceCard {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  width: 16rem;
  height: 14rem;
  padding: 1rem;
  cursor: pointer;
  position: relative;
}

.service-name {
  font-weight: bold;
}

.category {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.category-service-container {
  padding: 0 1.5rem;
}

.service-description {
  margin-bottom: 0.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-icon {
  margin-left: 1.5rem;
  cursor: pointer;
}

.service-cards-container {
  display: flex;
  gap: 1.5rem;
}

.active-status {
  position: absolute;
  margin: 1rem;
  bottom: 0;
  left: 0;
}

.status-received {
  background-color: #d9f2dd !important;
  color: #43bf57 !important;
  border-radius: 30px;
  font-weight: 600;
  padding: 5px 15px;
}

.status-pending {
  background-color: #f9e0cf !important;
  color: #ff7f24 !important;
  border-radius: 30px;
  font-weight: 600;
  padding: 5px 15px;
}

.status-disabled {
  background-color: #f0d9d3 !important;
  color: #b24025 !important;
  border-radius: 30px;
  font-weight: 600;
  padding: 5px 15px;
}

.service-left-container {
  padding: 15px;
  height: 100%;
  width: 25%;
}

.service-right-container {
  color: var(--secondary-color);
  border-bottom: 4px solid var(--secondary-color);
  background-color: rgba(var(--secondary-color), 0.05);
}

.service-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.black-border {
  border: 1px solid black;
  border-radius: 8px;
}

.upload-container {
  border: 2px solid #b0b0b0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-top: 40px;
  padding: 160px 0;
}

.upload-icon {
  height: 100px;
  margin-bottom: 10px;
}

.upload-text {
  text-align: center;
}

.browse-label {
  color: var(--orange-color);
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  padding: 0 3px;
}

.image-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  margin-top: 30px;
}

.uploaded-image {
  height: 7rem;
  width: 7rem;
  object-fit: cover;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  color: red;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 1px;
  font-size: 30px;
  cursor: pointer;
  line-height: 0;
}

.add-more {
  color: var(--secondary-color);
  font-size: 18px;
  cursor: pointer;
}

.add-more i {
  font-style: normal;
}

.servicePackageContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1.5rem;
  position: relative;
}

.servicePackageContainer-disabled {
  background-color: lightgrey;
}

.service-divider {
  border-color: #000;
  border-width: 2px;
}

.servicePackageCheckbox {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.servicePackageCheckbox:checked {
  accent-color: var(--secondary-color);
}
.servicePackageCheckbox:not(:checked) {
  accent-color: #ffffff;
}

.serviceInput {
  width: 100%;
  border-radius: 5px;
  border-color: #cccccc;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  padding: 0.375rem 0.75rem;
  /* flex: 1; */
}

.servicePackageContainer {
  flex: 1 0 21%;
  margin: 1%;
  box-sizing: border-box;
}

.service-chip-active {
  padding: 8px 20px;
  margin: 0 5px 5px 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  font-weight: 600;
  border-radius: 32px;
  border: 1px solid #ccc;
}

.service-chip-inactive {
  padding: 8px 20px;
  margin: 0 5px 5px 5px;
  cursor: pointer;
  background-color: #fff;
  font-weight: 600;
  border-radius: 32px;
  border: 1px solid #ccc;
}

.package-dropdown-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.package-dropdown-menu {
  position: absolute;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px;
}

.package-menu-item {
  cursor: pointer;
  padding: 5px 20px;
  font-weight: "600";
}
