.user-left-container {
  padding: 15px;
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
}

.send-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.send-button {
  background-color: white;
  color: black;
  padding: 0 20px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  width: 100%;
  height: 40px;
}

.send-button:hover {
  background-color: #f0f0f0;
}
