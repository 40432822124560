/* Add this CSS code to your stylesheet */
@media (max-width: 768px) {
  .bnt-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bnt-actions button {
    margin: 4px 20px;
  }
}

.wrapper {
  width: 86%;
  height: 100vh;
  padding: 20px;
  margin-left: auto;
  background-color: #f8f9fa;
  overflow-y: auto; /* Add scroll if needed */
}

.wrapper-package {
  width: 86%;
  padding: 20px;
  margin-left: auto;
  overflow-y: auto; /* Add scroll if needed */
}

button {
  margin: 4px;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.center-content {
  text-align: center;
  width: 600px; /* Adjust the width as needed */
  padding: 20px;
  background-color: #fff;
}

.text-right {
  text-align: right;
}
