:root {
  --primary-color: #febd59;
  --secondary-color: #ff7f24;
  --tertiary-color: #b24025;
  --green-color: #43bf57;
  --border-color: #dee1e6;
}

.App {
  text-align: center;
  background-color: #f7f0e4;
}

.btn {
  background-color: var(--primary-color);
  color: black;
  border: none !important;
}

/* #root {
  background-color: #f7f0e4;
} */

.button-primary {
  display: block;
  width: 100%;
  background-color: var(--primary-color);
  color: #000;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
}

.button-primary a {
  color: #000;
  text-decoration: none;
  display: block;
}

a {
  text-decoration: none;
}

.button-secondary {
  display: block;
  width: 100%;
  background-color: #fff;
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 0;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-approve {
  flex: 1;
  background-color: var(--primary-color);
  color: #000;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  padding: 8px 20px;
  box-sizing: border-box;
}

.button-reject {
  flex: 1;
  background-color: #fff;
  color: #000;
  border: 2px solid #b24025;
  border-radius: 5px;
  padding: 7px 20px;
  box-sizing: border-box;
}

.button-edit {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 7px 20px;
  box-sizing: border-box;
  font-weight: 600;
  margin-right: 10px;
}

.button-submit {
  background-color: var(--primary-color);
  color: #000;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  padding: 8px 20px;
  box-sizing: border-box;
}

.form-conatiner {
  background-color: #f7f0e4;
}

.login-logo {
  height: 150px;
  width: 280px;
}

.react-confirm-alert-button-group > button {
  background-color: #febd59;
  color: black;
}

.react-confirm-alert {
  background-color: #f7f0e4;
}

.alert-model {
  position: fixed;
}

.image-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

table th {
  background-color: #fafafb !important;
  font-weight: 600 !important;
}

.multiline {
  max-width: 300px;
  overflow-wrap: break-word;
}

.modal-title {
  font-size: large;
  font-weight: bold;
}

.modal-header {
  border-bottom: 0px;
}

.modal-body {
  padding: 0px 1rem;
}

.modal-footer {
  border-top: 0px;
}

.modal-footer .btn-primary {
  background-color: var(--primary-color);
}

.status-received {
  background-color: #d9f2dd !important;
  color: #43bf57 !important;
  border-radius: 30px;
  font-weight: 600;
  padding: 5px 15px;
}

.status-failed {
  background-color: #ffe5d3 !important;
  color: #ff7f24 !important;
  border-radius: 30px;
  font-weight: 600;
  padding: 5px 15px;
}

.blue-text {
  color: blue;
}

.customHeader {
  background-color: white;
  padding: 0.6rem 1.5rem 0px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContent h2 {
  margin: 0;
}

/* .headerContent button {
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
} */

.customHeader hr {
  border: none;
  border-top: 1px solid #eee;
}
