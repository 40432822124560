.booking-details {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background: white;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(50%);
  transition: transform 0.3s ease-out;
  overflow-y: auto;
}

.booking-details.open {
  transform: translateX(0);
}

.booking-details .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.booking-details .section {
  margin-top: 20px;
}

.booking-details h2,
.booking-details h4 {
  margin: 0;
}
.booking-details .centered-title {
  width: 100%;
  text-align: center;
}
.booking-details p {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}

.booking-details hr {
  margin: 10px 0;
}

.booking-details strong {
  font-weight: bold;
  font-size: 14px;
}

.icon {
  cursor: pointer;
}

.text-right-overflow {
  max-width: 200px;
  overflow-wrap: "break-word";
  display: "inline-block";
  text-align: "right";
}
