.calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.react-calendar {
  border: none;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 500px;
}

.react-calendar__tile {
  border-radius: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 !important;
}

.weekdays {
  background: var(--green-color);
  color: black;
}

.weekends {
  background: var(--tertiary-color);
  color: white;
}

.react-calendar__tile--now:enabled:focus.weekdays,
.react-calendar__tile--now:enabled:hover.weekdays,
.react-calendar__tile--active:enabled:focus.weekdays,
.react-calendar__tile--active:enabled:hover.weekdays {
  background: var(--green-color);
  color: black;
}

.react-calendar__tile--now:enabled:focus.weekends,
.react-calendar__tile--now:enabled:hover.weekends,
.react-calendar__tile--active:enabled:focus.weekends,
.react-calendar__tile--active:enabled:hover.weekends {
  background: var(--tertiary-color);
  color: white;
}

.react-calendar__tile--now:enabled:focus.selected,
.react-calendar__tile--now:enabled:hover.selected,
.react-calendar__tile--active:enabled:focus.selected,
.react-calendar__tile--active:enabled:hover.selected {
  background: var(--tertiary-color);
  color: white;
}

.available {
  background: var(--green-color);
}

.not-available {
  background: var(--tertiary-color);
}
