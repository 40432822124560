.wrapper .nav-link {
  color: black;
  font-weight: normal;
  text-decoration: none;
  border: none;
}

.wrapper .nav-link.active {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #000000;
}

.nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
}

.rounded {
  border-radius: 50px !important;
}

.search-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-icon {
  position: relative;
  width: 20rem;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  z-index: 2;
}

.padding-left {
  padding-left: 2.5rem;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-dropdown {
  position: absolute;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  /* margin-top: 9rem; */
  margin-right: 10px;
}
