/* Sidebar.css */
.sidebar {
  width: 14%;
  height: 90%;
  position: fixed;
  left: 0;
  background-color: white;
  color: black;
  padding: 0px;
  box-sizing: border-box;
  transition: white 0.2s ease;
}

.gratif-logo {
  height: 40px;
  margin: 10px 0;
}

.logo-container {
  text-align: center;
}

.logo {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  transition: transform 0.2s ease;
}

.logo:hover {
  transform: scale(1.1);
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 15px 0px 15px 25px;
  color: black;
  text-decoration: none;
  font-size: 17px;
}

.sidebar li.selected {
  background-color: #f7f0e4;
}

.sidebar li:hover {
  background-color: #f7f0e4;
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.selected-text {
  color: var(--secondary-color);
  font-weight: bold;
}
