.m-calendar .react-calendar__tile:disabled {
  background-color: white;
}

.m-calendar .react-calendar__tile--now {
  background: white;
}

.m-calendar .react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: white;
  color: black;
}

.m-tooltip {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 100px;
  z-index: 1000;
  border: 1px solid black;
  position: relative;
}

.m-tooltip::before {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid black;
}

.m-tooltip::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
}

.m-calendar .react-calendar__tile {
  position: relative;
}

.date-marker {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltipCircle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.timeSlotContainer {
  background-color: white;
  padding: 20px;
  flex-grow: 1;
}

.timeSlotWrapper {
  display: flex;
  flex-wrap: wrap;
}

.timeSlotHeader {
  margin-top: 1rem;
}

.strike-through-date {
  color: grey;
  text-decoration: line-through;
}
