.wrapper .nav-link.active {
  color: var(--secondary-color);
  border-bottom: 4px solid var(--secondary-color);
  background-color: rgba(var(--secondary-color), 0.05);
}

#photographer-controlled-tab.active {
  color: var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  background-color: rgba(var(--secondary-color), 0.05);
}

.photographer-details-right-side .nav-link.active {
  color: var(--secondary-color);
  border-bottom: 4px solid var(--secondary-color);
  background-color: rgba(var(--secondary-color), 0.05);
}

.photographer-details-right-side #photographer-details-controlled-tab.active {
  color: var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  background-color: rgba(var(--secondary-color), 0.05);
}

.wrapper .nav-link.active {
  color: var(--secondary-color);
  border-bottom: 4px solid var(--secondary-color);
  background-color: rgba(var(--secondary-color), 0.05);
}

#photographer-details-controlled-tab.active {
  color: var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  background-color: rgba(var(--secondary-color), 0.05);
}

.photographer-details-container {
  display: flex;
}

.photographer-left-container {
  padding: 15px;
  height: 100%;
  width: 25%;
}

.photographer-right-container {
  padding: 15px;
  height: 100%;
  width: 75%;
}

.photographer-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.photographer-detail-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.photographer-divider {
  border-color: #bcc1ca;
}

.photographer-bold-text {
  font-weight: bold;
}

.photographer-grey-text {
  color: grey;
}

.photographer-experience {
  display: flex;
  flex-direction: column;
}

.photographer-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.status-accepted {
  background-color: #d9f2dd;
  color: #43bf57;
  border-radius: 30px;
  font-weight: bold;
  padding: 5px 15px;
}

.status-pending {
  background-color: #ffe5d3;
  color: #ff7f24;
  border-radius: 30px;
  font-weight: bold;
  padding: 5px 15px;
}

.status-rejected {
  background-color: #f0d9d3;
  color: var(--tertiary-color);
  border-radius: 30px;
  font-weight: bold;
  padding: 5px 15px;
}

.status-deactivated {
  background-color: #d3d3d3;
  color: #707070;
  border-radius: 30px;
  font-weight: bold;
  padding: 5px 15px;
}

/* Photographer gear */
.photographer-gear-details {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
}

.photographer-gear-details .left-section,
.photographer-gear-details .right-section {
  flex: 1;
  padding: 20px;
}

.photographer-gear-details .right-section {
  border-left: 1px solid #ddd;
}

.photographer-gear-details h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.photographer-gear-details p {
  margin-bottom: 10px;
}

/* PhotographerPortfolio */
.photographer-portfolio-details {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
}

.photographer-portfolio-details .left-section {
  flex: 0 0 30%;
  padding: 20px;
}

.photographer-portfolio-details .right-section {
  flex: 0 0 70%;
  padding: 20px;
}

.social-link-section {
  margin-bottom: 2rem;
}

.social-link-section h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .photographer-portfolio-details .right-section {
  border-left: 1px solid #ddd;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

.photographer-portfolio-details h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.photographer-portfolio-details a {
  margin-bottom: 10px;
}

.portfolio-image {
  /* width: 306px; */
  height: 192px;
  margin: 10px;
  cursor: pointer;
}
